import React, { useEffect } from "react";

import Layout from "../layout";
import SEO from "../components/seo/seo";

import "./index.scss";



const IndexPage = () => {
  const handleWheel = (event) => {
    event.preventDefault();
    const scrollableElement = document.getElementById('ProjectsCarousel');
    scrollableElement.scrollLeft += event.deltaY;
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('ProjectsCarousel');
    scrollableElement.addEventListener('wheel', handleWheel);
    return () => {
      scrollableElement.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <Layout>
      <SEO title="Home" keywords={["Portfolio", "Edmond Hwang", "Edy Hwang"]} />

      <section className="wrapper-container index-page">
        <h3>edmond hwang</h3>

        <div className="port-links pull-right">
          <a
              title="GitHub"
              href="https://github.com/edhwang90"
              rel="noopener noreferrer"
              target="_blank"
            >
              github
            </a>
            <a title="Resume"
              href="https://drive.google.com/file/d/1WkaMCYSdFWvVPsDSZZKH1JENR-vNdXA2/view?usp=drive_link"
              rel="noopener noreferrer"
              target="_blank"><b>resume</b></a>
        </div>

        <div id="ProjectsCarousel" className="projects-carousel">
          <a className="carousel-nav-link" href="/recipebook" title="cooking web app">
            <div className="project rb-page">
              <h3>helping with recipe usage</h3>

              <ul className="tags">
                <li>web</li>
                <li>AI</li>
                <li>accessibility</li>
              </ul>
            </div>
          </a>
          <a className="carousel-nav-link" href="/fareshare" title="carpooling mobile app">
            <div className="project fs-page">
              <h3>facilitating ride-sharing</h3>

              <ul className="tags">
                <li>mobile</li>
                <li>trust</li>
                <li>transparency</li>
              </ul>
            </div>
          </a>
          <a className="carousel-nav-link" href="/gov-financial-app" title=".gov redesign">
            <div className="project gov-rd">
              <h3>assisting in a re-design</h3>

              <ul className="tags">
                <li>.gov</li>
                <li>design thinking</li>
                <li>sales</li>
              </ul>
            </div>
          </a>
          <a className="carousel-nav-link" href="/react-storybook" title="pattern library">
            <div className="project rsb-pl">
              <h3>a pattern library</h3>

              <ul className="tags">
                <li>code</li>
                <li>react</li>
                <li>storybook.js</li>
              </ul>
            </div>
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
